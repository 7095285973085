@import "../../styles/helpers";

.counter {
    flex-shrink: 0;
    display: flex;
    align-items: center; }

.button {
    position: relative;
    top: -1px;
    flex-shrink: 0;
    border: 1px solid #ccc;
    border-radius: 50%;
    svg {
        fill: $n5;
        transition: fill .2s; }
    &:not(disabled) &:hover {
        svg {
            fill: $p1; } }
    &.disabled {
        pointer-events: none;
        svg {
            fill: $n6;
            @include dark {
                fill: $n3; } } } }

.button.small {
        height: 24px;
        width: 24px; }

.button.medium {
        height: 30px;
        width: 30px; }

.button.large {
        height: 36px;
        width: 36px; }

.number {
    padding: 0 12px;
    text-align: center;
    @include body-bold-2; }
