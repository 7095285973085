@import "../../../styles/helpers";

.background {
    @include t {
        display: none; }
    .container {
        max-width: 1440px;
        @include w {
            padding: 0 40px; } } }

.head {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 400px;
    border-radius: 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    &.active {
        .btns {
            opacity: 0; }
        .file {
            opacity: 1;
            visibility: visible; } } }

.btns {
    position: absolute;
    right: 16px;
    bottom: 16px;
    transition: opacity .2s;
    .button {
        color: $n8;
        svg {
            fill: $n8; }
        @include dark {
            box-shadow: inset 0 0 0 2px $n6;
            &:hover {
                background: $n6;
                color: $n2;
                svg {
                    fill: $n2; } } }
        &:not(:last-child) {
            margin-right: 16px; } } }

.file {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: $n8;
    opacity: 0;
    visibility: hidden;
    transition: all .2s;
    @include m {
        align-items: flex-start;
        padding-top: 40px; }
    &:before,
    &:after {
        content: "";
        position: absolute; }
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($n1, .3); }
    &:after {
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        border: 2px dashed $n6;
        border-radius: 20px; }
    input {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        font-size: 1400px;
        opacity: 0; }
    svg {
        margin-bottom: 24px;
        fill: $n8;
        @include m {
            width: 24px;
            height: 24px;
            margin-bottom: 12px; } }
    .button {
        position: absolute;
        right: 16px;
        bottom: 16px;
        z-index: 4;
        @include t {
            bottom: 48px; }
        @include m {
            right: 50%;
            transform: translateX(50%); } } }

.wrap {
    position: relative;
    z-index: 2;

    .info {
        font-size: 24px;
        line-height: (32/24);
        font-weight: 600;
        @include m {
            font-size: 16px; } } }

.text {
    @include body-2;
    @include m {
        font-size: 12px; } }
