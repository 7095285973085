@import "../../../styles/helpers";

.section {
    @include d {
        margin-bottom: 96px; }
    @include m {
        margin-bottom: 64px; }

    .container {
        max-width: 1440px;
        @include w {
            padding: 0 40px; }
        @include t {
            padding: 0; }
        @include m {
            padding: 0 8px; } } }

.preview {
    position: relative;
    &:before {
        content: "";
        display: block;
        padding-bottom: 60.63%;
        @include d {
            padding-bottom: 64%; }
        @include m {
            display: none; } }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        object-fit: cover;
        @include t {
            border-radius: 0; }
        @include m {
            position: static;
            height: 700px;
            border-radius: 16px 16px 0 0;
            object-position: 50% 100%; } } }

.wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 145px 80px;
    @include d {
        padding: 98px 80px; }
    @include t {
        padding: 80px 40px; }
    @include m {
        padding: 80px 32px;
        text-align: center; }

    .info {
        margin-bottom: 24px;
        color: $n2;
        @include d {
            font-size: 16px; }
        @include m {
            font-size: 24px; } } }

.title {
    max-width: 450px;
    margin-bottom: 16px;
    @include dark {
        color: $n2; } }

.panel {
    margin: -175px 80px 0;
    @include w {
        margin: -175px 40px 0; }
    @include d {
        margin-top: -148px; }
    @include t {
        margin: -110px 16px 0; }
    @include m {
        margin: -210px 8px 0; } }

.body {
    margin-top: 32px;
    @include d {
        margin-top: 24px; } }

.controls {
    display: flex;
    margin-bottom: 16px;
    @include m {
        flex-wrap: wrap; }
    .button {
        @include m {
            margin-bottom: 12px;
            padding: 0 24px; }
        &:not(:last-child) {
            margin-right: 16px;
            @include m {
                margin-right: 8px; } }
        &.active {
            box-shadow: inset 0 0 0 2px $n6;
            background: $n6;
            color: $n2; } } }

.row {
    display: flex;
    padding-right: 100px;
    @include x {
        margin: 0 -20px;
        padding-right: 90px; }
    @include t {
        margin: 0;
        padding-right: 70px; }
    @include m {
        flex-wrap: wrap;
        padding-right: 0; }
    & > div {
        &:first-child,
        &:nth-child(2) {
            flex: 0 0 248px;
            @include x {
                flex: 0 0 225px; }
            @include d {
                flex: 0 0 200px; }
            @include t {
                flex: 0 0 170px; }
            @include m {
                flex: 0 0 50%; } }
        &:nth-child(3) {
            flex-grow: 1; } } }

.search {
    top: auto;
    bottom: 16px;
    transform: translateY(0);
    @include x {
        right: 0; }
    @include t {
        bottom: 0; }
    @include m {
        position: static;
        width: 100%;
        height: 48px;
        margin-top: 12px;
        border-radius: 24px; } }
