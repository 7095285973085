@import "../../../styles/helpers";

.card {
    display: flex;
    flex-direction: column;
    border-radius: 24px;
    overflow: hidden;
    background: $n8;
    border: 1px solid $n6;
    color: $n1;
    transition: border-color .2s;
    &:hover {
        border-color: $n5;
        .preview {
            img {
                transform: scale(1.1); } } }
    @include dark {
        background: $n1;
        border-color: $n3;
        color: $n8; }
    &.row {
        flex-direction: row;
        border-radius: 16px;
        @include x {
            display: block; }
        .preview {
            flex-shrink: 0;
            width: 256px;
            @include x {
                width: 100%; }
            &:before {
                display: none;
                @include x {
                    display: block; } } }
        .body {
            display: flex;
            flex-direction: column;
            flex-grow: 1; }
        .line {
            margin-bottom: 16px; }
        .price {
            display: flex;
            flex-direction: column-reverse; }
        .old {
            margin: 6px 0 0;
            text-decoration: none; }
        .option {
            &:nth-child(2) {
                flex-shrink: 0; } }
        .foot {
            margin-top: auto; } }
    &.car {
        .line {
            margin-bottom: 0;
            @include d {
                margin-bottom: 8px; } }
        .price {
            display: flex;
            flex-direction: column-reverse; }
        .old {
            margin: 6px 0 0;
            text-decoration: none; }
        .option {
            &:nth-child(2) {
                flex-shrink: 0; } } } }

.preview {
    height: 300px;
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-bottom: 68.6%; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s; } }

.category {
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 2; }

.body {
    flex-grow: 1;
    padding: 24px 22px; }

.line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px; }

.title {
    @include body-bold-2; }

.price {
    margin-left: 16px;
    padding: 6px 8px;
    box-shadow: inset 0 0 0 2px $p4;
    border-radius: 4px;
    text-align: center;
    @include hairline-2;
    color: $p4; }

.old {
    margin-bottom: 6px;
    text-decoration: line-through;
    color: $n5; }

.foot {
    padding-top: 16px;
    border-top: 1px solid $n6;
    @include dark {
        border-color: $n2; } }
