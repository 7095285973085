@import "../../../styles/helpers";

.card {
    border-radius: 16px;
    overflow: hidden;
    background: $n8;
    color: $n2;
    @include dark {
        background: $n2;
        color: $n8; }
    &:hover {
        .subtitle {
            color: $p1;
            @include dark {
                color: $p1; } }
        .preview {
            img {
                transform: scale(1.1); } } } }

.preview {
    position: relative;
    overflow: hidden;
    &:before {
        content: "";
        display: block;
        padding-bottom: 89%; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s; } }

.body {
    padding: 20px; }

.line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.subtitle {
    @include body-bold-2;
    transition: color .2s; }

.description {
    @include caption-2;
    color: $n3;
    @include dark {
        color: $n6; } }
