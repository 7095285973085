@import "../../styles/helpers";

.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    row-gap: 10px;

    .home {
        &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 9px;
            margin-left: 16px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777E90'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; } } }

.item {
    @include button-2;
    font-weight: 500;
    color: $n5;
    &:not(:last-child) {
        &:after {
            content: "";
            display: inline-block;
            width: 6px;
            height: 9px;
            margin-left: 16px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.793.793a1 1 0 0 0 0 1.414L3.586 5 .793 7.793a1 1 0 0 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777E90'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto; } }
    &:last-child {
        color: #475467; } }

.link {
    color: var(--text-link);
    font-weight: 600;
    transition: color .2s;
    &:hover {
        color: var(--text-link); } }
