@import "../../styles/helpers";

.messenger {

    .container {
        position: relative;
        display: flex;
        padding-left: 396px;
        @include x {
            padding-left: 360px; }
        @include d {
            padding-left: 148px; } } }

.users {
    position: fixed;
    top: 88px;
    left: 0;
    bottom: 0;
    width: 396px;
    @include x {
        width: 360px; }
    @include d {
        width: 148px; }
    @include m {
        top: 112px;
        width: 100%; } }

.messages {
    flex-grow: 1; }

.location {
    flex-shrink: 0;
    width: 496px;
    @include x {
        width: 364px; }
    @include t {
        display: none; } }



