@import "../../styles/helpers";

.inner {
    position: relative;

    .head {
        margin-bottom: 80px;
        padding-right: 120px;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 40px;
            padding: 0; }
        .info {
            margin-top: 12px; } } }

.headSmall {
    margin-bottom: 48px;
    @include m {
        margin-bottom: 40px; } }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -8px; } }
