@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Target all the buttons that have direct child svg */
button:has(> svg) {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  font-family: "Inter", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  /* --primary-color: #3b71fe; */
  --primary-color: #0C111D;
  --light-color: #d0d5dd;
  --black-color: #000;
  --error-color: #ef4444;

  --text-primary: #101828;
  --text-secondary: #344054;
  --text-link: #1570EF;
}

a {
  color: var(--primary-color);
  cursor: pointer;
}

.asterisk::after {
  content: "*";
  color: var(--error-color);
  margin-left: 4px;
}