@import "../../styles/helpers";

.section {

    .container {
        max-width: 1440px;
        @include w {
            padding: 0 40px; }
        @include t {
            padding: 0; } } }

.inner {
    padding: 112px 80px;
    background: $n7;
    border-radius: 24px;
    @include w {
        padding: 80px 64px; }
    @include x {
        padding: 64px 40px; }
    @include t {
        border-radius: 0; }
    @include m {
        padding: 64px 24px; }
    @include dark {
        background: #18191D;
        border-width: 1px;
        border-style: solid;
        border-color: $n3;
        @include t {
            border-width: 1px 0; } } }

.head {
    margin-bottom: 80px;
    text-align: center;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 40px; } }

.title {
    margin-bottom: 12px; }

.slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -64px -10px 0;
    @include x {
        margin: -48px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }

.item {
    flex: 0 0 calc(20% - 48px);
    width: calc(20% - 48px);
    margin: 64px 24px 0;
    @include x {
        flex: 0 0 calc(20% - 32px);
        width: calc(20% - 32px);
        margin: 48px 16px 0; }
    @include d {
        flex: 0 0 calc(25% - 32px);
        width: calc(25% - 32px); }
    @include t {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px); }
    @include m {
        display: block;
        width: 100%;
        margin: 0; }
    &:nth-child(n+8) {
        @include d {
            display: none; }
        @include t {
            display: block; } } }
