@import "../../styles/helpers";

.body {
    position: relative;
    z-index: 3;
    padding: 80px 0 136px;
    @include d {
        padding-bottom: 112px; }
    @include t {
        padding: 0 0 92px; }
    @include m {
        padding: 0 0 64px; }
    .container {
        display: flex;
        align-items: flex-start;
        @include t {
            display: block; } } }

.profile {
    flex-shrink: 0;
    width: 352px;
    margin-top: -193px;
    text-align: center;
    @include t {
        width: auto;
        margin: 0 -16px 48px; } }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; }
    &.big {
        width: 160px;
        height: 160px;
        margin: 0 auto 12px; } }


.update {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    @include caption-bold-2;
    color: $n4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $p1;
        svg {
            fill: $p1; } } }

.outer {
    max-width: 663px;
    & > div {
        padding: 8px 8px 32px; } }

.file {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 342px;
    padding: 32px;
    border-radius: 16px;
    border: 2px dashed $n5;
    overflow: hidden;
    text-align: center;
    @include m {
        height: 280px; }
    @include dark {
        background: $n2;
        border: 2px dashed $n3; } }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 400px;
    opacity: 0; }

.icon {
    margin-bottom: 24px;
    svg {
        fill: $p1; } }

.format {
    @include body-bold-1;
    @include m {
        font-size: 18px; } }

.note {
    @include body-2;
    color: $n4;
    @include m {
        margin-top: 8px;
        font-size: 14px; } }

.gallery {
    margin-top: 32px;

    .info {
        margin-bottom: 16px;
        text-align: center;
        @include caption-2;
        color: $n4; } }

.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -24px -16px 0;
    @include m {
        margin: -16px -8px 0; } }

.list {
    .avatar {
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        margin: 24px 16px 0;
        @include m {
            width: 40px;
            height: 40px;
            margin: 16px 8px 0; }
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover; } } }

.wrapper {
    flex-grow: 1;
    padding-left: 80px;
    @include d {
        padding-left: 48px; }
    @include t {
        padding-left: 0; } }

.details {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 40px; } }


